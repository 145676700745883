@import '../../styles/variables';
@import '../../styles/mixins/_label';

.ColumnHeader {
  background-color: $clColumnHeader;
  display: flex;
  height: $columnHeaderHeight;
  border-left: 1px solid rgba(white, 0.15);
  justify-content: space-between;

  h2 {
    @include label;
    text-decoration: none;
    font-size: 1.06em;
    margin: 0;
    color: white;
    line-height: 50px;
    padding-left: 15px;
  }
}
