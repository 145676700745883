@import '../../styles/variables';
@import '../../styles/mixins/_label';

.Popup {
  position: fixed;
  left: 0;
  top: headerHeight;
  width: 100%;
  height: calc(100% - #{$headerHeight});
  background-color: rgba($clSecondaryDark, 0.8);
  z-index: 100;
  padding: 30px 40px 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  animation-name: popupIntro;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-duration: 0.3s;

  .content {
    width: 1200px;
    max-width: 100%;
    height: 850px;
    max-height: 100%;
    border-radius: 2px;

    box-shadow: 2px 2px 20px rgba($clBlack, 0.3),
      2px 2px 5px rgba($clBlack, 0.5);

    .header {
      height: 50px;
      background-color: $clBlack;
      color: white;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      display: flex;
      justify-content: space-between;
      border-bottom: 5px solid $clSecondary;

      h2 {
        @include label;
        text-decoration: none;
        font-size: 1.06em;
        margin: 0;
        color: white;
        line-height: 50px;
        padding-left: 15px;
      }

      .close {
        width: 50px;
        height: 50px;
        position: relative;
        transition: background-color $transition;
        cursor: pointer;

        &:hover {
          background-color: rgba(white, 0.1);
        }

        &::after,
        &::before {
          content: '';
          width: 15px;
          height: 3px;
          border-radius: 2px;
          background-color: white;
          position: absolute;
          right: 18px;
          top: 23px;
          transition: opacity $transition, transform $transition;
          opacity: 1;
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }

    .body {
      overflow-y: auto;
      margin: 0;
      padding: 0;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      height: 800px;
      max-height: calc(100% - 50px);
      background-color: white;

      iframe {
        margin: 0;
        border: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes popupIntro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
