@import '../../styles/variables';

.HelpTooltip {
  display: inline-block;
  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    color: $clSecondary;
    font-size: 0.8em;
    font-weight: 600;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: rgba($clSecondary, 0.1);
      transform: translate(-8px, -8px);
    }
  }

  .__react_component_tooltip {
    padding-left: 10px;
    padding-right: 10px;
  }
}
