@import '../../styles/variables';

.APIInfo {
  color: rgba(white, 0.6);
  padding: 10px;
  font-size: 0.75em;
  max-width: calc(100vw - 650px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.7;
  transition: opacity $transition;

  &:hover {
    opacity: 1;
    color: rgba(white, 0.8);
  }

  .label {
    color: rgba(white, 0.6);
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    padding: 4px;
    margin-right: 5px;
    border-radius: 5px;
    background-color: rgba($clSecondary, 0.4);
  }
}
