@import '../../styles/variables';

.ObjectDetails {
  padding-bottom: 15px;

  .image-container {
    background-position: center;
    background-size: cover;
    img {
      height: 225px;
      object-fit: contain;
      width: 100%;
    }
  }

  .details {
    padding: 0 15px;

    h2 {
      font-size: 1.1em;
      margin: 7px 0;

      mark {
        background-color: rgba($clPrimary, 0.15);
        border-bottom: 1px dotted $clPrimary;
      }

      .score {
        float: right;
        margin-top: -5px;
        margin-right: -10px;
        background-color: rgba($clPrimary, 0.15);
        padding: 5px;
        border-radius: 5px;
        .PercentageLabel {
          margin-left: 7px;
          span {
            color: rgba($clBlack, 0.6);
          }
        }
      }
    }

    .attributes {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.87em;
      color: $clText;

      > div {
        width: 50%;
        padding: 5px 0 5px 20px;
        background-position: left 3px;
        background-repeat: no-repeat;

        &.large {
          width: 100%;
        }
      }
    }

    p {
      color: $clText;
      margin: 10px 0 15px;

      mark {
        background-color: rgba($clPrimary, 0.15);
        border-bottom: 1px dotted $clPrimary;
      }

      .read-more {
        display: block;
        text-decoration: underline;
        padding: 5px 0;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity $transition;

        &:hover {
          opacity: 1;
        }
      }
    }

    a {
      text-decoration: none;
      display: inline-block;
      padding: 10px 10px 10px 35px;

      background-position: 5px center;
      background-repeat: no-repeat;
      background-size: 24px;
    }
  }
}
