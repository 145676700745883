@import '../../styles/variables';

.QueryColumn {
  flex-shrink: 0;
  width: $columnLeftWidth;
  background-color: white;
  box-shadow: 1px 0px 2px rgba($clBlack, 0.1);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: opacity $transition;
  z-index: 2;
  user-select: none;

  .Scrollbar > div:last-child {
    opacity: 0;
    transition: opacity $transition;
  }

  &:hover {
    .Scrollbar > div:last-child {
      opacity: 1;
    }
  }

  &.loading {
    opacity: 0.7;
  }
}
