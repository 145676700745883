@import '../../styles/variables';

.Example {
  position: relative;
  margin: 15px;
  width: 180px;
  text-decoration: none !important;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: 1px 1px 10px rgba($clBlack, 0.1);
  transition: box-shadow $transition;
  padding-bottom: 25px;

  &:hover {
    box-shadow: 1px 3px 15px rgba($clBlack, 0.2);
  }

  .image {
    height: 140px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .score {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: mix(white, $clPrimary, 85%);
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    .PercentageLabel {
      color: $clBlack;
      margin-left: 7px;
      span {
        color: rgba($clBlack, 0.6);
      }
    }
  }

  h2 {
    margin: 8px 0;
    padding: 0 10px;
    font-size: 1.2em;
    color: $clBlack;
  }

  p {
    margin: 0 0 7px 0;
    padding: 0 10px;
    color: $clGreyMedium;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    font-weight: 600;
    font-family: $fontHeading;
  }
}
