@import '../../styles/variables';

.Examples {
  background-color: $clGreyLight;
  padding: 20px 0;
  min-height: 380px;
  
  > h2 {
    margin: 10px auto;
    width: 100%;
    max-width: 600px;
    text-align: left;
  }
  .cases {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0;
  }
}
