@import '../../styles/variables';

.Errors {
  position: fixed;
  bottom: 0;
  left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 999;

  li {
    display: block;
    padding: 15px;
    width: 100%;
    text-align: center;
    background: $clRed;
    font-weight: 600;
    color: white;
    border-bottom: 1px solid rgba(white, 0.3);

    span {
      opacity: 0.5;
    }
  }
}
